/* =================================== */
/*  3. Layouts
/* =================================== */

#main-wrapper {
    background:#fff;
  }
  
  .box #main-wrapper {
      max-width: 1200px;
      margin: 0 auto;
      @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  }
  
  .section {
    position: relative;
    padding: 6.5rem 0;
    overflow:hidden;
  }
  
  @include media-breakpoint-down(md) {
      .section {
          padding: 3.5rem 0;
      }
  }
  
  /*== Fullscreen Height ==*/
  .fullscreen{
      min-height: 100vh!important;
  }
  
  /*== Fullscreen Height ==*/
  .fullscreen-with-header{
      min-height: calc(100vh - 67px)!important;
  }
  
  /*== Scroll Down Arrow ==*/
  
  .scroll-down-arrow {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    left: 50%;
    margin-left: -16px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    text-align: center;
    color: #fff;
    .animated {
      position: relative;
      animation: fadeInDown 1.5s infinite;
      -webkit-animation: fadeInDown 1.5s infinite;
      -moz-animation: fadeInDown 1.5s infinite;
      -o-animation: fadeInDown 1.5s infinite;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      top: -25px;
      opacity: 0;
    }
    100% {
      top: 10px;
      opacity: 1;
    }
  }
  
  /*== Heading Separator Line ==*/
  .heading-separator-line{width:80px;}
/* =================================== */
/*  Extras
/* =================================== */

/* Bootstrap Specific */

.form-control, .custom-select {
    border-color: #dae1e3;
    font-size: 1.25rem;
    color: #656565;
  }
  
  .form-control:not(.form-control-sm) {
    padding: .810rem .96rem;
    height:inherit;
  }
  
  .form-control-sm{font-size:14px;}
  
  .icon-inside {
    position: absolute;
    right: 15px;
    top: calc(50% - 11px);
    pointer-events: none;
    font-size: 18px;
    font-size: 1.125rem;
    color: #c4c3c3;
    z-index:3;
  }
  .form-control-sm + .icon-inside {
    font-size: 0.875rem !important;
    font-size: 14px;
    top: calc(50% - 13px);
  }
  
  select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
    height: auto;
    padding-top: .700rem;
    padding-bottom: .700rem;
  }
  .custom-select:not(.custom-select-sm){
    height:calc(3.05rem + 2px);
    padding-top: .700rem;
    padding-bottom: .700rem;}
  .col-form-label-sm{font-size:13px;}
  .custom-select-sm{padding-left:5px!important; font-size:14px;}
  .custom-select:not(.custom-select-sm).border-0{height:3.00rem;}
  
  .form-control:focus, .custom-select:focus{
    @include box-shadow(0 0 5px rgba(128, 189, 255, 0.5));
  }
  .form-control:focus[readonly]{box-shadow:none;}
  
  .input-group-text {
    border-color: #dae1e3;
    background-color:#f1f5f6;
    color: #656565;
  }
  
  .form-control {
    &::-webkit-input-placeholder {
      color: #b1b4b6;
    }
    &:-moz-placeholder {
      /* FF 4-18 */
      color: #b1b4b6;
    }
    &::-moz-placeholder {
      /* FF 19+ */
      color: #b1b4b6;
    }
    &:-ms-input-placeholder, &::-ms-input-placeholder {
      /* IE 10+ */
      color: #b1b4b6;
    }
  }
  
  /* Form Dark */
  .form-dark {
    .form-control, .custom-select {
      border-color: #232a31;
      background:#232a31;
      color: #fff;
    }
    .form-control:focus{border-color: #80bdff!important;}
    .form-control {
      &::-webkit-input-placeholder {
        color: #777b7f;
      }
      &:-moz-placeholder {
        /* FF 4-18 */
        color: #777b7f;
      }
      &::-moz-placeholder {
        /* FF 19+ */
        color: #777b7f;
      }
      &:-ms-input-placeholder, &::-ms-input-placeholder {
        /* IE 10+ */
        color: #777b7f;
      }
    }
    .custom-select {
      color: #777b7f;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.3)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
      background-size: 13px 15px;
      border-color: #232a31;
      background-color:#232a31;
    }
    .icon-inside {
      color: #777b7f;
    }
  }
  
  /*  Input with only bottom border  */
  .form-border {
    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
      border-radius: 0px;
      padding-left: 0px!important;
      color: rgba(0, 0, 0, 1);
      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      &:-moz-placeholder {
        /* FF 4-18 */
        color: rgba(0, 0, 0, 0.4);
      }
      &::-moz-placeholder {
        /* FF 19+ */
        color: rgba(0, 0, 0, 0.4);
      }
      &:-ms-input-placeholder, &::-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(0, 0, 0, 0.4);
      }
    }
    .custom-select {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
      border-radius: 0px;
      padding-left: 0px;
      color: rgba(0, 0, 0, 0.4);
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(0,0,0,0.3)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
      background-size: 13px 15px;
    }
    .form-control:focus, .custom-select:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    }
    .form-control:not(output):-moz-ui-invalid, .custom-select:not(output):-moz-ui-invalid {
      &:not(:focus), &:-moz-focusring:not(:focus) {
        border-bottom: 2px solid #b00708;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    .icon-inside {
      color: rgba(0, 0, 0, 0.25);
    }
    select option {
      color: #666;
    }
  }
  
  
  .form-border-light {
    .form-control {
      border-bottom: 2px solid rgba(250, 250, 250, 0.3);
      color: rgba(250, 250, 250, 1);
      &::-webkit-input-placeholder {
        color: rgba(250, 250, 250, 0.7);
      }
      &:-moz-placeholder {
        /* FF 4-18 */
        color: rgba(250, 250, 250, 0.7);
      }
      &::-moz-placeholder {
        /* FF 19+ */
        color: rgba(250, 250, 250, 0.7);
      }
      &:-ms-input-placeholder, &::-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(250, 250, 250, 0.7);
      }
    }
    .custom-select {
      border-bottom: 2px solid rgba(250, 250, 250, 0.3);
      color: rgba(250, 250, 250, 1);
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.6)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
      background-size: 13px 15px;
    }
    .form-control:focus, .custom-select:focus {
      border-bottom: 2px solid rgba(250, 250, 250, 0.8);
    }
    .icon-inside {
      color: #999;
    }
    select option {
      color: #333;
    }
  }
  
  
  .btn {
    padding:0.8rem 2.6rem;
    font-weight:500;
    border-width:2px;
    @include transition(all .5s ease);
  }
  
  .btn-sm {
    padding: 0.5rem 1rem;
  }
  
  .btn:not(.btn-link) {
    @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
    &:hover {
      @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.3));
      @include transition(all .5s ease);
    }
  }
  
  .input-group-append .btn, .input-group-prepend .btn {
    @include box-shadow(none);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  .input-group-append .btn:hover, .input-group-prepend .btn:hover {
    @include box-shadow(none);
  }
  
  @include media-breakpoint-only(xs) {
    .btn:not(.btn-sm){
      padding: .810rem 2rem;
    }
    .input-group > {
    .input-group-append > .btn, .input-group-prepend > .btn {
      padding: 0 0.75rem;
    }
  }
  }
  
  .bg-primary, .badge-primary {
      background-color: $primary-color !important;
  }
  .bg-secondary {
      background-color: $secondary-color !important;
  }
  
  .text-primary{
      color: $primary-color !important;
  }
  
  .btn-link{
      color: $primary-color;
  }
  .btn-link:hover {
      color: $primary-color-hover;
  }
  .text-secondary{
      color: $secondary-color !important;
  }
  .text-muted {
      color: #6c757d !important;
  }
  .text-light{
      color:#dee3e4!important;
  }
  
  a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover{background-color: $primary-color-hover!important;}
  
  .border-primary {
      border-color: $primary-color !important;
  }
  .border-secondary {
      border-color: $secondary-color !important;
  }
  
  .btn-primary{
    background-color: $primary-color;
    border-color: $primary-color;
    color:#212529;
    &:hover {
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
      color:#212529;
    }
  }
  
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active{
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
    
  }
  
  .btn-primary.focus, .btn-primary:focus{
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
  }
  
  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus{
      @include box-shadow(none);
  }
  
  .btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  
  .btn-outline-primary, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active{
    color: $primary-color;
    border-color: $primary-color;
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color:#212529;
    }
  }
  
  .btn-outline-secondary{
    color: $secondary-color;
    border-color: $secondary-color;
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: #fff;
    }
  }
  
  .progress-bar,
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link, .dropdown-item.active, .dropdown-item:active{
      background-color: $primary-color;
  }
  .page-item.active .page-link,
  .custom-radio .custom-control-input:checked ~ .custom-control-label:before,
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
  .custom-control-input:checked ~ .custom-control-label:before{
      background-color: $primary-color;
      border-color: $primary-color;
  }
  .list-group-item.active{
      background-color: $primary-color;
      border-color: $primary-color;
  }
  .page-link {
    color: $primary-color;
    &:hover {
      color: $primary-color-hover;
    }
  }
  
  /* Pagination */
  
  .page-link {
    border: none;
    border-radius: 0.25rem;
    margin: 0 0.22rem;
    font-size: 16px;
    font-size: 1rem;
    &:hover {
      background-color: #e9eff0;
    }
  }
  
  /* Vertical Multilple input group */
  
  .vertical-input-group .input-group {
    &:first-child {
      padding-bottom: 0;
      * {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child {
      padding-top: 0;
      * {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &:not(:last-child):not(:first-child) {
      padding-top: 0;
      padding-bottom: 0;
      * {
        border-radius: 0;
      }
    }
    &:not(:first-child) * {
      border-top: 0;
    }
  }
  
  /* styles-switcher */
  #styles-switcher {
    background: #fff;
    width: 202px;
    position: fixed;
    top: 35%;
    z-index: 99;
    padding: 20px;
    left: -202px;
    ul {
      padding: 0;
      margin:0;
      li {
        list-style-type: none;
        width: 32px;
        height: 32px;
        margin: 4px 2px;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
        transition: all .2s ease-in-out;
        &.cyan {
          background: #00ffff;
        }
        &.green-yellow {
          background: #adff2f;
        }
        &.lime-punch {
          background: #d6ed17;
        }
        &.orange {
          background: #fdbb2e;
        }
        &.yellow {
          background: #f5df4e;
        }
        &.pale-golden-rod {
          background: #eee8aa;
        }
        &.spring-green {
          background: #00fe9c;
        }
        &.violet {
          background: #ff91fb;
        }
        
        &.active {
          transform: scale(0.7);
          cursor:default;
        }
      }
    }
    .switcher-toggle {
      position: absolute;
      background: #555;
      color: #fff;
      font-size: 1.25rem;
      border-radius: 0px 4px 4px 0;
      right: -40px;
      top: 0;
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }
  
  input:-internal-autofill-selected {
      background-color: transparent;
  }
  
  #styles-switcher.right{left:auto; right: -202px;}
  #styles-switcher.right .switcher-toggle{right: auto; left: -40px; border-radius: 4px 0px 0px 4px;}
  
  
  //portfolio image modal
  .portfolio-image-modal{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    img{
        max-width: 80%;
        max-height: 80%;
    }
    .btn-close{
        position: absolute;
        top: 15px;
        right: 15px;
    }
  }